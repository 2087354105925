import {
  submitEvent,
  submitMerge,
  submitMove,
  trackOnNextPageImpression,
} from "@otto-ec/tracking-bct";

export function sendLoadTrackingEvent(combolist, currentSlides) {
  const combolistPayload = combolist.getTrackingPayload("loaded");

  const visibleCombos = [].filter.call(combolist.combos, (combo) =>
    currentSlides.includes(combo.position - 1)
  );
  const combosPayload = [].flatMap.call(visibleCombos, (combo) => {
    const comboTrackingPayload = combo.getLoadTrackingPayloadForParent(
      combolistPayload.id
    );
    const articlesTrackingPayload = [].map.call(combo.articles, (article) => {
      return article.getLoadTrackingPayloadForParentAndStatus(
        comboTrackingPayload.id
      );
    });
    return [comboTrackingPayload, ...articlesTrackingPayload];
  });

  submitMerge({}, [combolistPayload, ...combosPayload]);
}

export function sendComboClickTrackingEvent(combolist, clickedCombo) {
  function buildComboMovePayload() {
    const pageCluster = window.o_util.misc.getPagecluster();
    const movePayload = {
      "wk.promo_AttributionFeature": `${combolist.promoType}_${pageCluster}`,
      "wk.promo_AttributionSource": clickedCombo.contentSource
        ? clickedCombo.contentSource
        : "ComboListDetail",
    };
    return movePayload;
  }

  function buildComboPayload() {
    const combolistClickTrackingPayload =
      combolist.getTrackingPayload("clicked");
    return {
      name: "click",
      features: [
        combolistClickTrackingPayload,
        clickedCombo.getClickTrackingPayloadForParent(
          combolistClickTrackingPayload.id
        ),
      ],
    };
  }

  submitMove(buildComboMovePayload(), buildComboPayload());
  trackOnNextPageImpression({ promo_Click: combolist.promoType });
}

export function sendArticleClickTrackingEvent(
  combolist,
  clickedCombo,
  clickedArticle
) {
  function buildArticleMovePayload() {
    const pageCluster = window.o_util.misc.getPagecluster();
    const movePayload = {
      "wk.promo_AttributionFeature": `${combolist.promoType}_${pageCluster}`,
      "wk.promo_AttributionSource": clickedCombo.contentSource
        ? clickedCombo.contentSource
        : "ComboListDetail",
    };
    return movePayload;
  }

  function buildArticlePayload() {
    const combolistWidgetClickTrackingPayload =
      combolist.getTrackingPayload("clicked");
    const widgetParentId = combolistWidgetClickTrackingPayload.id;
    const combolistComboClickTrackingPayload =
      clickedCombo.getClickTrackingPayloadForParent(widgetParentId);
    const comboParentId = combolistComboClickTrackingPayload.id;
    const combolistArticlesClickTrackingPayload =
      clickedArticle.getClickTrackingPayloadForParentAndStatus(comboParentId);

    return {
      name: "click",
      features: [
        combolistWidgetClickTrackingPayload,
        combolistComboClickTrackingPayload,
        combolistArticlesClickTrackingPayload,
      ],
    };
  }

  submitMove(buildArticleMovePayload(), buildArticlePayload());

  trackOnNextPageImpression({ promo_Click: combolist.promoType });
}

export function sendScrollRightOrLeftTrackingEvent(
  combolist,
  featureOrder,
  positions,
  action
) {
  const combolistPayload = combolist.getTrackingPayload("visible");
  const combosPayload = [];

  if (combolist.combos) {
    for (let step = 0; step < positions.length; step += 1) {
      const position = positions[step];

      if (combolist.combos[position]) {
        const currentVisibleCombo = combolist.combos[position];

        const comboTrackingPayload =
          currentVisibleCombo.getVisibleTrackingPayloadForParent(
            combolistPayload.id
          );
        const articlesTrackingPayload = [].map.call(
          currentVisibleCombo.articles,
          (article) => {
            return article.getVisibleTrackingPayloadForParentAndStatus(
              comboTrackingPayload.id
            );
          }
        );
        combosPayload.push(comboTrackingPayload);
        combosPayload.push(...articlesTrackingPayload);
      }
    }
  }
  const payload = {
    name: action,
    features: [combolistPayload, ...combosPayload],
  };

  submitEvent({}, payload);
}

export function sendVisibleTrackingEvent(combolist) {
  const combolistPayload = combolist.getTrackingPayload("visible");

  const visibleCombos = [].filter.call(
    combolist.combos,
    (combo) => combo.isVisible === true
  );
  const combosPayload = [].flatMap.call(visibleCombos, (combo) => {
    const comboTrackingPayload = combo.getVisibleTrackingPayloadForParent(
      combolistPayload.id
    );
    const articlesTrackingPayload = [].map.call(combo.articles, (article) => {
      return article.getVisibleTrackingPayloadForParentAndStatus(
        comboTrackingPayload.id
      );
    });
    return [comboTrackingPayload, ...articlesTrackingPayload];
  });

  const featuresPayload = [combolistPayload, ...combosPayload];
  const visibleCombolistPayload = {
    name: "scroll",
    features: featuresPayload,
  };

  submitEvent({}, visibleCombolistPayload);
}
